//Ref. https://reacttraining.com/react-router/web/example/auth-workflow

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Proptypes from 'prop-types';

function PrivateRoute(props) {
  const {
    component: Component,
    appLoaded,
    profile = {},
    policies = [],
    isAuthenticated = false,
    publicRedirect = '/',
    privateRedirect = '/app',
    permissions: requiredPermissions,
    ...rest
  } = props;

  const { isAdmin = false } = profile;

  const getAllowedComponent = () => {
    const profilePermissionsWithName = policies.map(profilePermission => {
      const { policies } = profilePermission;
      const { name, section, read, write } = policies;
      return {
        name,
        sections: section.split(','),
        read,
        write
      };
    });

    let currentPermissions = {
      isAdmin,
      can: (action, permission) =>
        profilePermissionsWithName.some(
          ({ name, sections, ...actions }) =>
            sections.includes(permission) && actions[action]
        )
    };

    if (!requiredPermissions || !requiredPermissions.length) {
      // Se permissions è vuoto, consenti l'accesso all'utente autenticato, qualunque sia il tipo
      return <Component currentPermissions={currentPermissions} {...props} />;
    }

    // Se permissions non è un array, impedisci l'accesso alla route settando i permessi dell'utente ad un array vuoto
    const currentRouteProfilePermissions = Array.isArray(requiredPermissions)
      ? profilePermissionsWithName.filter(({ sections }) =>
          requiredPermissions.some(p => sections.includes(p))
        )
      : [];

    const canRead = currentRouteProfilePermissions.some(({ read }) => read);
    const canWrite = currentRouteProfilePermissions.some(({ write }) => write);

    currentPermissions = {
      ...currentPermissions,
      canRead,
      canWrite
    };

    if (canRead) {
      // Se permissions non è vuoto, consenti l'accesso all'utente autenticato le cui policy includono almeno la lettura per la route
      return <Component currentPermissions={currentPermissions} {...props} />;
    }

    return (
      <Redirect
        push={false}
        to={{
          pathname: privateRedirect,
          state: { from: props.location }
        }}
      />
    );
  };

  return (
    <Route
      {...rest}
      render={props => {
        return isAuthenticated ? (
          appLoaded ? (
            getAllowedComponent()
          ) : (
            ''
          )
        ) : (
          <Redirect
            push={false}
            to={{
              pathname: publicRedirect,
              state: { from: props.location }
            }}
          />
        );
      }}
    />
  );
}

PrivateRoute.propTypes = {
  component: Proptypes.oneOfType([
    Proptypes.func.isRequired,
    Proptypes.object.isRequired
  ]),
  isAuthenticated: Proptypes.bool,
  allow: Proptypes.arrayOf(Proptypes.string)
};

export default PrivateRoute;
