import axios from 'axios';

const createInterceptor = (statusToIntercept, cb) => {
  return axios.interceptors.response.use(
    response => response,
    error => {
      const { response: axiosResponse = {} } = error || {};
      const { status } = axiosResponse;

      if (status === statusToIntercept && typeof cb === 'function') {
        cb(error);
      }

      return error;
    }
  );
};

export default createInterceptor;
