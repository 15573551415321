import axios from 'axios';

export const fetchMe = async token => {
  try {
    const response = await axios({
      method: 'get',
      url: process.env.REACT_APP_API_ME,
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export const updateMe = async (params = {}) => {
  const { token, data } = params;
  try {
    const response = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/me/update`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export const fetchPolicies = async token => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/me/policies`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export const changePassword = async ({ token, password } = {}) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/me/change-password`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: { password }
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    // Sentry TODO
    return { error };
  }
};
