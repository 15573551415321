import React from 'react';

function ThemedSuspense() {
  return (
    <div className="w-full h-screen p-6 text-lg font-medium text-gray-600 light:text-gray-400 light:bg-gray-900">
      Loading...
    </div>
  );
}

export default ThemedSuspense;
