import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { useCookies } from 'react-cookie';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import createInterceptor from '../../lib/helpers/interceptor';
import { fetchMe, fetchPolicies } from '../../lib/fetch/me';
import { initialState, authReducer } from './authReducer';

const AuthContext = createContext([{}, () => {}]);

const AuthProvider = ({ children }) => {
  const { t } = useTranslation('LOGIN');
  const alert = useAlert();

  const [cookies = {}, setCookie, removeCookie] = useCookies(['token']);
  const { token } = cookies;
  const isAuthenticated = !!(token && token.length > 0);

  const [state, dispatch] = useReducer(authReducer, {
    ...initialState,
    token,
    isAuthenticated
  });

  const dispatchFunction = (action = {}) => {
    const { type, token, maxAge } = action;

    switch (type) {
      case 'login':
        setCookie('token', token, {
          path: '/',
          maxAge: maxAge || process.env.REACT_APP_TOKEN_EXPIRATION
        });
        break;
      case 'logout':
        removeCookie('token');
        break;
      default:
        break;
    }

    return dispatch(action);
  };

  useEffect(() => {
    createInterceptor(401, () => {
      alert.error(t('COMMON:UNAUTHORIZED'));
      dispatch({ type: 'logout' });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const callMe = async () => {
      const { error, data } = await fetchMe(token);
      if (error) {
        dispatch({ type: 'logout' });
        alert.error(t('COMMON:GENERIC_ERROR'));
        return;
      }
      dispatch({ type: 'setProfile', profile: data.user });
    };

    const callPolicies = async () => {
      const { error, data } = await fetchPolicies(token);
      if (error) {
        alert.error(t('COMMON:GENERIC_ERROR'));
        return;
      }
      dispatch({ type: 'setPolicies', policies: data.policies });
    };

    if (isAuthenticated) {
      callPolicies().then(() => callMe());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <AuthContext.Provider value={[state, dispatchFunction]}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, AuthContext, useAuth };
