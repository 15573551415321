const initialState = {
  isAuthenticated: false,
  appLoaded: false,
  profile: {},
  token: null
};

function authReducer(state, action = {}) {
  const { type, token, profile, policies } = action;

  switch (type) {
    case 'logout':
      localStorage.clear();
      return { ...initialState };
    case 'login':
      return { ...state, isAuthenticated: true, token };
    case 'setProfile':
      return { ...state, appLoaded: true, profile };
    case 'setPolicies':
      return { ...state, policies };
    default:
      return { ...state };
  }
}

export { initialState, authReducer };
