import { configureStore } from '@reduxjs/toolkit';
import dashboardReducer from '../feature/dashboard/dashboardSlice';
import contrattiReduced from '../feature/contratti/contrattiSlice';

export const store = configureStore({
  reducer: {
    dashboard: dashboardReducer,
    contratti: contrattiReduced
  }
});
