import React, { useState, useContext, createContext } from 'react';
import { Modal, ModalBody } from '@windmill/react-ui';
import { SpinnerIcon } from '../icons';
import { useTranslation } from 'react-i18next';

const LoadingContext = createContext();

const LoadingProvider = ({ children }) => {
  const { t } = useTranslation('COMMON');
  const [blocking, setBlocking] = useState(false);

  const showLoading = () => setBlocking(true);
  const hideLoading = () => setBlocking(false);

  const loadingContext = {
    showLoading,
    hideLoading
  };

  return (
    <LoadingContext.Provider value={loadingContext}>
      <Modal isOpen={blocking}>
        <ModalBody>
          <p className="mt-2 mb-10 font-semibold text-blue-500 text-lg text-center">
            {t('WAIT')}
          </p>
          <SpinnerIcon className="w-16 h-16 m-auto animate-spin" />
        </ModalBody>
      </Modal>
      {children}
    </LoadingContext.Provider>
  );
};

const useLoading = () => useContext(LoadingContext);

export { LoadingProvider, useLoading };
