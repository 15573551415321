import React from 'react';
import { Switch } from 'react-router-dom';
import { useAuth } from './AuthProvider';

const RoutesWrapper = ({ publicRedirect, privateRedirect, children }) => {
  const [{ appLoaded, isAuthenticated, profile, policies } = {}] = useAuth();

  const childrenComponents = React.Children.map(children, child => {
    return React.cloneElement(child, {
      appLoaded,
      profile,
      policies,
      isAuthenticated,
      publicRedirect,
      privateRedirect
    });
  });

  return <Switch>{childrenComponents}</Switch>;
};

export default RoutesWrapper;
