import React from 'react';
import { types } from 'react-alert';

const alertStyle = {
  position: 'relative',
  backgroundColor: '#ffffff',
  color: '#142935',
  padding: '15px 10px 15px 25px',
  textTransform: 'uppercase',
  borderRadius: '10px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxShadow: '0px 2px 2px 2px rgba(0, 0, 0, 0.03)',
  width: '330px',
  fontWeight: '600',
  boxSizing: 'border-box',
  textAlign: 'center'
};

const buttonStyle = {
  marginLeft: '20px',
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  color: '#142935'
};

const borderStyle = {
  position: 'absolute',
  left: '0',
  top: '0',
  width: '12px',
  height: '100%',
  borderTopLeftRadius: '10px',
  borderBottomLeftRadius: '10px'
};

const iconStyle = {
  marginRight: '20px'
};

const AlertTemplate = ({ style, options, message, close }) => (
  <div style={{ ...alertStyle, ...style }}>
    {options.type === types.INFO && (
      <div style={borderStyle} className="bg-yellow-500"></div>
    )}
    {options.type === types.SUCCESS && (
      <div style={borderStyle} className="bg-green-400"></div>
    )}
    {options.type === types.ERROR && (
      <div style={borderStyle} className="bg-red-400"></div>
    )}

    {options.type === types.INFO && (
      <i style={iconStyle} className="fas fa-info-circle text-orange-400"></i>
    )}
    {options.type === types.SUCCESS && (
      <i style={iconStyle} className="fas fa-check-circle text-green-400"></i>
    )}
    {options.type === types.ERROR && (
      <i
        style={iconStyle}
        className="fas fa-exclamation-circle text-red-400"
      ></i>
    )}
    {message}
    <button onClick={close} style={buttonStyle}>
      <i className="fas fa-times" />
    </button>
  </div>
);

export default AlertTemplate;
