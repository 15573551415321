import React, { lazy } from 'react';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import translations from './translations';
import moment from 'moment';
import 'moment/locale/it';
import { CookiesProvider } from 'react-cookie';
import {
  AuthProvider,
  RoutesWrapper,
  PrivateRoute,
  PublicRoute
} from './context/Auth';
import { LoadingProvider } from './context/LoadingContext';
import { Provider as AlertProvider, positions } from 'react-alert';
import { QueryParamProvider } from 'use-query-params';
import AlertTemplate from './components/AlertTemplate';

const Layout = lazy(() => import('./containers/Layout'));
const Login = lazy(() => import('./pages/Login'));
const ForgotPassword = lazy(() => import('./pages/Forgot'));
const Page404 = lazy(() => import('./pages/404'));

i18next.init({
  resources: translations,
  lng: 'it',
  keySeparator: false,
  interpolation: {
    escapeValue: false
  }
});

moment.locale('it');

function App() {
  return (
    <>
      <I18nextProvider i18n={i18next}>
        <AlertProvider
          template={AlertTemplate}
          position={positions.TOP_RIGHT}
          timeout={5000}
        >
          <CookiesProvider>
            <AuthProvider>
              <LoadingProvider>
                <Router>
                  <QueryParamProvider ReactRouterRoute={Route}>
                    <AccessibleNavigationAnnouncer />
                    <RoutesWrapper publicRedirect="/" privateRedirect="/app">
                      <PublicRoute path="/login" component={Login} />
                      <PublicRoute
                        path="/forgot-password"
                        component={ForgotPassword}
                      />
                      {/* Place new routes over this */}
                      <PrivateRoute path="/app" component={Layout} />
                      <Redirect exact from="/" to="/login" />
                      <Route component={Page404} />
                    </RoutesWrapper>
                  </QueryParamProvider>
                </Router>
              </LoadingProvider>
            </AuthProvider>
          </CookiesProvider>
        </AlertProvider>
      </I18nextProvider>
    </>
  );
}

export default App;
