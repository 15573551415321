import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: 0,
  partners: [],
  partnerSelected: null
};

export const contrattiSlice = createSlice({
  name: 'contratti',
  initialState,
  reducers: {
    setPartnerSelected: (state, action) => {
      const result = state.partners.find(
        partner => partner.name === action.payload
      );
      state.partnerSelected = result;
    },
    setPartners: (state, action) => {
      state.partners = action.payload;
    },
    resetPartnerSelected: (state, action) => {
      state.partnerSelected = null;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setPartnerSelected, setPartners, resetPartnerSelected } =
  contrattiSlice.actions;

export default contrattiSlice.reducer;
