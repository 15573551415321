import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const initialState = {
  value: 0,
  tempFilters: {
    from: moment().utc().startOf('month').toISOString().substring(0, 10),
    to: moment().utc().endOf('month').toISOString().substring(0, 10),
    selectedUser: 0
  },
  filters: {
    from: moment().utc().startOf('month').toISOString().substring(0, 10),
    to: moment().utc().endOf('month').toISOString().substring(0, 10),
    selectedUser: 0
  }
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setFromDate: (state, action) => {
      state.tempFilters.from = action.payload;
    },
    setToDate: (state, action) => {
      state.tempFilters.to = action.payload;
    },
    setSelectedUser: (state, action) => {
      state.tempFilters.selectedUser = action.payload;
    },
    setFilters: state => {
      state.filters = state.tempFilters;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setFromDate, setToDate, setSelectedUser, setFilters } =
  dashboardSlice.actions;

export default dashboardSlice.reducer;
